<template>
    <v-card>
        <v-card-text>
            <div class="text-subtitle-1 d-flex justify-space-between">

                <div class="text-subtitle-1">Uso de quirófanos {{moment('02-'+mes, 'DD-YYYY-MM').format('MMMM YYYY')}}</div>
                <div>{{promIntern}}/dia prom.</div>
                <page-month
                    :mes.sync="mes"
                />
            </div>

            <apexchart
                type='pie'
                :options="options"
                :series="series"
            />

        </v-card-text>
    </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    mounted(){
        //this.estaFechaOcupacion = this.moment(new Date()).format('MM/YYYY')
        this.mes = this.moment(new Date()).format('YYYY-MM')
        //this.cargaDatos()
    },
    components:{
        'apexchart':VueApexCharts,
        'page-month':()=>import('@/components/com/PageMonth'),
    },
    data:()=>({
        menuMes:false,
        mes:'',
        estaFechaOcupacion:"",
        etiquetas:[
            'Realizadas',
            'Diferidas',
            'Canceladas',
            'No procesadas',
            'En sala'
        ],
        options:{},
        series:[],
        datosTodos:{}
    }),
    methods:{
        cambiaMes(){
            this.menuMes=false
            this.setHoy()
        },
        setHoy(){
            this.mes = this.moment(new Date()).format('YYYY-MM')
        },
        async cargaDatos(){

            try{
                let req = await this.$http.get('/dashboard/listCirugias',{params:{fecha:this.mes}})
                //console.log(req.data.root)
                //let total = []
                //total.push(req.data.root)
                this.options={
                    labels:this.etiquetas,
                    dataLabels:{
                        formatter: function (val) {
                            return val.toFixed(2)+'%'
                        },
                    },
                    legend:{
                        formatter: function(seriesName, opts) {
                            return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
                        }
                    },
                }

                this.series=req.data.root


            } catch(err) {
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        promIntern(){
            let mesX = this.moment('02'+this.mes, 'DD-MM-YYYY').format('MM')
            let yearX = this.moment('02'+this.mes, 'DD-MM-YYYY').format('YYYY')
            //let tot = (this.series[0]/3)/new Date(yearX, mesX, 0).getDate()
            let tot = (this.series[0])/new Date(yearX, mesX, 0).getDate()
            return tot.toFixed(2)
        },
    },
    watch:{
        mes(){
            this.cargaDatos()
        },
    },

}
</script>

<style>

</style>